<template>
  <loginForm type="forgetPassword" :pageList="pageList" />
</template>

<script>
import loginForm from '@/components/loginForm'

export default {
  name: 'ForgetPassword',
  components: {
    loginForm
  },
  data () {
    return {
      pageList: [
        {
          name: 'Lupa Password',
          activate: true
        }
      ]
    }
  }
}
</script>
